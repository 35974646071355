import React from "react"
import { PageProps } from "gatsby"
import { useIntl } from "gatsby-plugin-intl"
import classNames from "classnames"
import Layout from "../../components/Layout/Layout"
import SEO from "../../components/seo"
import componentStyles from "./backlite-media.module.scss"
import DataSortIllustration from "../../assets/images/projects/backlite-media.png"
import DataSortResult1 from "../../assets/images/projects/backlite-media-result-1.png"
import DataSortResult2 from "../../assets/images/projects/backlite-media-result-2.png"
import DataSortResult3 from "../../assets/images/projects/backlite-media-result-3.png"
import DataSortResult4 from "../../assets/images/projects/backlite-media-result-4.png"
import SmallProjectCard from "../../components/SmallProjectCard/SmallProjectCard"
import { findIndex, projectsList } from "../../hooks"
import {
  getBigThumbnailByLink,
  useBigProjectsCardsThumbnails,
} from "../../hooks/useBigPreviewProjectsCardsThumbnails"
import projectsStyles from "../projects.module.scss"
import LearnMoreSection from "../../components/LearnMoreSection/LearnMoreSection"
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

const firstListItems = [
  "projects.backlite-media.solution-description.list.first-text",
  "projects.backlite-media.solution-description.list.second-text",
  "projects.backlite-media.solution-description.list.third-text",
  "projects.backlite-media.solution-description.list.forth-text",
  "projects.backlite-media.solution-description.list.fifth-text",
  "projects.backlite-media.solution-description.list.sixth-text",
]

const cubeItems = [
  "projects.backlite-media.technology-background.paragraph-3-list.first-text",
  "projects.backlite-media.technology-background.paragraph-3-list.second-text",
  "projects.backlite-media.technology-background.paragraph-3-list.third-text",
]

const resultsItems = [
  "projects.backlite-media.results.list.first-text",
  "projects.backlite-media.results.list.second-text",
  "projects.backlite-media.results.list.third-text",
  "projects.backlite-media.results.list.forth-text",
]

const challengeItems = [
  "projects.backlite-media.challenge.paragraph-1-list.item-1",
  "projects.backlite-media.challenge.paragraph-1-list.item-2",
  "projects.backlite-media.challenge.paragraph-1-list.item-3",
  "projects.backlite-media.challenge.paragraph-1-list.item-4",
]

const DataSort: React.FC<PageProps> = ({ location }) => {
  const intl = useIntl()
  const projects = projectsList
  const projectsThumbnails = useBigProjectsCardsThumbnails()

  const bannerClasses = classNames(componentStyles.banner)
  const bannerContainerClasses = classNames(
    componentStyles.bannerContainer,
    "container",
    "grid-2"
  )
  const challengeClasses = classNames(
    componentStyles.challenge,
    "container"
  )
  const challengeTitleClasses = classNames(
    componentStyles.challengeTitle,
    "large-section-title",
    "section-title-h2"
  )
  const challengeListClasses = classNames(
    componentStyles.challengeList,
    "grid-3"
  )
  const solutionDescriptionClasses = classNames(
    componentStyles.solutionDescription,
    "container"
  )
  const solutionDescriptionTitleClasses = classNames(
    componentStyles.solutionDescriptionTitle,
    "large-section-title",
    "section-title-h2"
  )
  const numberedListClasses = classNames(
    componentStyles.solutionDescriptionNumberedList,
    "grid-3"
  )
  const numberedListWrapperClasses = classNames(
    componentStyles.solutionDescriptionNumberedListWrapper,
    "grid-4"
  )

  const technologyBackgroundClasses = classNames(
    componentStyles.technologyBackground,
    "container"
  )
  const technologyBackgroundTitleClasses = classNames(
    componentStyles.technologyBackgroundTitle,
    "large-section-title",
    "section-title-h2"
  )
  const technologyBackgroundTextListClasses = classNames(
    componentStyles.technologyBackgroundTextList,
    "grid"
  )

  const resultsClasses = classNames(componentStyles.results, "container")
  const resultsTitleClasses = classNames(
    componentStyles.resultsTitle,
    "large-section-title",
    "section-title-h2"
  )
  const resultsListClasses = classNames(componentStyles.resultsList, "grid-4")

  const projectsTitleClasses = classNames("large-section-title")
  const blockPreview = classNames(projectsStyles.blockPreview, "container")
  const pathname = location.pathname
  const projectUrl = pathname.split("/")[3]
  const projectIndex = findIndex(projectUrl)

  const breakpoints = useBreakpoint()

  const lastIndex = cubeItems.length - 1

  return (
    <Layout location={location}>
      <SEO
        language={intl.locale}
        description={intl.formatMessage({
          id: "projects.backlite-media.seo.description",
        })}
        title={intl.formatMessage({ id: "projects.backlite-media.seo.title" })}
        pathname={location.pathname}
      />
      <section className={bannerClasses}>
        <div className={bannerContainerClasses}>
          <div className={componentStyles.bannerWrapper}>
            <h1 className={componentStyles.bannerTitle}>
              {intl.formatMessage({
                id: "projects.backlite-media.banner.title",
              })}
            </h1>
          </div>
          <div className={componentStyles.bannerImage}>
            <img
              className={componentStyles.bannerImageMainImage}
              src={DataSortIllustration}
              alt="Illustration with map markers"
              width="395"
              height="360"
            />
          </div>
        </div>
      </section>
      <section className="container">
        <div className={componentStyles.clientCompany}>
          <h2 className="large-section-title section-title-h2">
            {intl.formatMessage({
              id: "projects.backlite-media.banner.client-company",
            })}
          </h2>
          <div className={componentStyles.sectionText}>
            {intl.formatMessage({
              id: "projects.backlite-media.banner.client-name",
            })}
          </div>
        </div>
      </section>
      <section className={challengeClasses}>
        <div className={componentStyles.challengeTextBlock}>
          <h2 className={challengeTitleClasses}>
            {intl.formatMessage({
              id: "projects.backlite-media.challenge.title",
            })}
          </h2>
          <p className={componentStyles.challengeText}>
            {intl.formatMessage({
              id: "projects.backlite-media.challenge.paragraph-1",
            })}
          </p>
        </div>
        <ul className={challengeListClasses}>
          {challengeItems.map((text) => (
            <li
              key={text}
              className={componentStyles.technologyBackgroundTilesItem}
            >
              {intl.formatMessage({
                id: text,
              })}
            </li>
          ))}
        </ul>
      </section>
      <section className={solutionDescriptionClasses}>
        <div className={componentStyles.solutionDescriptionTextBlock}>
          <h2 className={solutionDescriptionTitleClasses}>
            {intl.formatMessage({
              id: "projects.backlite-media.solution-description.title",
            })}
          </h2>
          <p className={componentStyles.solutionDescriptionText}>
            {intl.formatMessage({
              id: "projects.backlite-media.solution-description.text",
            })}
          </p>
        </div>
        <div className={numberedListWrapperClasses}>
          <ol className={numberedListClasses}>
            {firstListItems.map(translatePath => (
              <li
                key={translatePath}
                className={componentStyles.solutionDescriptionNumberedListItem}
              >
                <p
                  className={
                    componentStyles.solutionDescriptionNumberedListItemText
                  }
                >
                  {intl.formatMessage({
                    id: translatePath,
                  })}
                </p>
              </li>
            ))}
          </ol>
        </div>
      </section>
      <section className={technologyBackgroundClasses}>
        <h2 className={technologyBackgroundTitleClasses}>
          {intl.formatMessage({
            id: "projects.backlite-media.technology-background.title",
          })}
        </h2>
        <ul className={technologyBackgroundTextListClasses}>
          <li className={componentStyles.technologyBackgroundTextItem + ' ' + componentStyles.item1}>
            {intl.formatMessage({
              id: "projects.backlite-media.technology-background.paragraph-1",
            })}
          </li>
          <li className={componentStyles.technologyBackgroundTextItem + ' ' + componentStyles.item2}>
            {intl.formatMessage({
              id: "projects.backlite-media.technology-background.paragraph-2",
            })}
          </li>
          <li className={componentStyles.technologyBackgroundTextItem + ' ' + componentStyles.item3}>
            {intl.formatMessage({
              id: "projects.backlite-media.technology-background.paragraph-3",
            })}
            <ul className={componentStyles.technologyBackgroundCubeList}>
              {cubeItems.map((translatePath, index) => (
                <li
                  key={translatePath}
                  className={componentStyles.technologyBackgroundCubeItem}
                >
                  {intl.formatMessage({
                    id: translatePath,
                  })}
                  {index === lastIndex && (breakpoints.mediaBefore600 && ';' || '.')}
                </li>
              ))}
            </ul>
          </li>
          <li className={componentStyles.technologyBackgroundTextItem + ' ' + componentStyles.item4}>
            {intl.formatMessage({
              id: "projects.backlite-media.technology-background.paragraph-4",
            })}
            {breakpoints.mediaBefore600 ? '.' : ';'}
          </li>
        </ul>
      </section>
      <section className={resultsClasses}>
        <h2 className={resultsTitleClasses}>
          {intl.formatMessage({
            id: "projects.backlite-media.results.title",
          })}
        </h2>
        <p className={componentStyles.resultsText}>
          {intl.formatMessage({
            id: "projects.backlite-media.results.text",
          })}
        </p>
        <ul className={resultsListClasses}>
          {resultsItems.map(translatePath => (
            <li key={translatePath} className={componentStyles.resultsItem}>
              {intl.formatMessage({
                id: translatePath,
              })}
            </li>
          ))}
        </ul>
        <div className="grid-2">
          <img
            src={DataSortResult1}
            className={componentStyles.resultsImage}
            alt={intl.formatMessage({
              id: "projects.backlite-media.results.first-image-alt",
            })}
            width="564"
            height="275"
          />
          <img
            src={DataSortResult2}
            className={componentStyles.resultsImage}
            alt={intl.formatMessage({
              id: "projects.backlite-media.results.second-image-alt",
            })}
            width="564"
            height="275"
          />
          <img
            src={DataSortResult3}
            className={componentStyles.resultsImage}
            alt={intl.formatMessage({
              id: "projects.backlite-media.results.third-image-alt",
            })}
            width="564"
            height="275"
          />
          <img
            src={DataSortResult4}
            className={componentStyles.resultsImage}
            alt={intl.formatMessage({
              id: "projects.backlite-media.results.forth-image-alt",
            })}
            width="564"
            height="275"
          />
        </div>
      </section>
      <section className={blockPreview}>
        <h2 className={projectsTitleClasses}>
          {intl.formatMessage({ id: "index.projects.next-project" })}
        </h2>
        {projects
          .filter(project => project.link !== projectUrl)
          .slice(projectIndex, projectIndex + 1)
          .map(({ title, color, link, textColor }) => {
            const thumbnail = getBigThumbnailByLink(link, projectsThumbnails)
            return (
              <SmallProjectCard
                key={title}
                title={title}
                thumbnail={thumbnail}
                link={link}
                color={color}
                textColor={textColor}
                buttonColor="orange"
              />
            )
          })}
      </section>
      <LearnMoreSection />
    </Layout>
  )
}
export default DataSort
